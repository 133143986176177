<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Forward Antrian</h5>
        </div>
        <div class="modal-body">
          <input
            type="hidden"
            class="form-control"
            v-model="queueId"
          >
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-4 col-form-label"
            >Unit Antrian Sekarang:</label>
            <div class="col-lg-6 my-auto">
              <span>{{ prevqueueUnit }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="example-text-input"
              class="col-4 col-form-label"
            >Unit Antrian Selanjutnya:</label>
            <div class="col-lg-6 my-auto">
              <unit-antrian
                v-model="queueUnitId"
                :validation="'required'"
                :value-label="'id'"
              ></unit-antrian>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="clearData"
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            data-dismiss="modal"
          >Close</button>
          <button
            type="button"
            class="btn btn-default btn-bold btn-upper btn-font-md"
            v-on:click="submitData"
          >Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnitAntrian from "./../../_select/UnitAntrian";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const QueueUserRepository = RepositoryFactory.get("queueUser");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  components: {
    UnitAntrian
  },
  data() {
    return {
      queueId: null,
      queueUnitId: null,
      prevqueueUnit: null
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    updateData: function (queueUser) {
      this.queueId = queueUser.id;
      if (queueUser.queueUnit) {
        this.prevqueueUnit = queueUser.queueUnit.displayName1;
      } else {
        this.prevqueueUnit = null;
      }
    },
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
          var params = {
            queueId: this.queueId,
            queueUnitId: this.queueUnitId
          };

          let instance = new SweetAlert().showConfirm({
            swalTitle: "Apakah Anda yakin?",
            swalText: "Antrian Pasien akan dipindahkan.",
            swalType: "info",
            onConfirmButton: function () {
              blockUI.blockModal();
              vx.forward(params);
            },
            onCancelButton: function () { }
          });
        }
      });
    },
    async forward(payload) {
      var vx = this;

      await QueueUserRepository.forwardQueueUser(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Antrian berhasil dipindahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Antrian gagal dipindahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.updateData(this.modalData);
    }
  }
};
</script>
